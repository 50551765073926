<template>
<div>
    <div class="content">
        <h1>用户注册协议</h1>
        <p style="text-indent:2em;">一、总则</p>
        <p style="text-indent:2em;">（一）平台的所有权和运营权归微在线公司所有。</p>
        <p style="text-indent:2em;">（二）用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与平台之间自动形成协议关系，用户应当受本协议的约束。用户必须完全同意所有服务条款并完成注册程序，才能成为平台的正式用户。</p>
        <p style="text-indent:2em;">（三）本协议可由平台随时更新，用户应当及时关注并同意本站不承担通知义务。本站的通知、公告、声明或其它类似内容是本协议的一部分。</p>
        <p style="text-indent:2em;">二、服务内容</p>
        <p style="text-indent:2em;">（一）平台的具体内容由本站根据实际情况提供。</p>
        <p style="text-indent:2em;">（二）本站仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。</p>
        <p style="text-indent:2em;">（三）如果用户提供的资料包含有不正确的信息，本平台保留结束用户使用网络服务资格的权利。</p>
        <p style="text-indent:2em;">三、用户账号</p>
        <p style="text-indent:2em;">（一）经本站注册系统完成注册程序并通过身份认证的用户即成为正式用户，可以获得本站规定用户所应享有的一切权限；未经身份认证仅享有本站规定的部分会员权限。平台有权对会员的权限设计进行变更。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且平台保留终止用户使用平台服务的权利。</p>
        <p style="text-indent:2em;">（二）用户只能按照注册要求使用真实手机号码注册。用户有义务保证密码和账号的安全，用户利用该密码和账号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本站不承担任何责任。如用户发现账号遭到未授权的使用或发生其他任何安全问题，应立即修改账号密码并妥善保管，如有必要，请通知本站。因黑客行为或用户的保管疏忽导致账号非法使用，本站不承担任何责任。</p>
        <p style="text-indent:2em;">（三）用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通告平台。</p>
        <p style="text-indent:2em;">四、使用规则</p>
        <p style="text-indent:2em;">（一）遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释（法释[]1号）》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。</p>
        <p style="text-indent:2em;">（二）用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本站任何页面发布、转载、传送含有下列内容之一的信息，否则本站有权自行处理并不通知用户：</p>
        <p style="text-indent:2em;">1、违反宪法确定的基本原则的；</p>
        <p style="text-indent:2em;">2、危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</p>
        <p style="text-indent:2em;">3、损害国家荣誉和利益的；</p>
        <p style="text-indent:2em;">4、煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p style="text-indent:2em;">5、破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p style="text-indent:2em;">6、散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p style="text-indent:2em;">7、散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
        <p style="text-indent:2em;">8、侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p style="text-indent:2em;">9、煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
        <p style="text-indent:2em;">10、以非法民间组织名义活动的；</p>
        <p style="text-indent:2em;">11、含有法律、行政法规禁止的其他内容的；</p>
        <p style="text-indent:2em;">12、不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论。</p>
        <p style="text-indent:2em;">（三）用户承诺对其发表或者上传于本站的所有信息（即属于《中华人民共和国著作权法》规定的作品，包括但不限于地图数据、文字、图片、音乐、电影、表演和录音录像制品和电脑程序等）均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本站被第三人索赔的，用户应全额补偿本站一切费用（包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用）。</p>
        <p style="text-indent:2em;">（四）当第三方认为用户发表或者上传于本站的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本站发送权利通知书时，用户同意本站可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本站将不会自动恢复上述删除的信息。</p>
        <p style="text-indent:2em;">（五）平台保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。若用户未遵守以上规定的，平台有权作出独立判断并采取暂停或关闭用户账号等措施。用户须对自己在网上的言论和行为承担法律责任。</p>
        <p style="text-indent:2em;">五、隐私保护</p>
        <p style="text-indent:2em;">（一）尊重您个人信息的隐私是平台的一项基本制度，平台将会采取合理的措施保护您的个人信息。平台保证不对外公开或向第三方提供您的个人信息，但下列情况除外：</p>
        <p style="text-indent:2em;">1、您的个人信息属于或者成为可公开获取的信息；</p>
        <p style="text-indent:2em;">2、由平台通过合法方式获知且无需对其履行保密义务的个人信息；</p>
        <p style="text-indent:2em;">3、通过可以合法披露此个人信息且无需对其履行保密义务的第三方获得的信息；</p>
        <p style="text-indent:2em;">4、事先获得您的明确授权；</p>
        <p style="text-indent:2em;">5、根据有关的法律法规的要求；</p>
        <p style="text-indent:2em;">6、按照相关政府主管部门或司法部门的要求；</p>
        <p style="text-indent:2em;">7、为维护社会公众的利益；</p>
        <p style="text-indent:2em;">8、为维护平台、其附属关联公司的合法权益。</p>
        <p style="text-indent:2em;">（二）您理解并同意，平台可能会与第三方合作向您提供该服务，在此情况下，如该第三方同意承担与平台同等的保护您个人信息的责任，则平台将授权第三方通过_微在线__定义接口调用经过加密的您的个人信息，平台保证第三方不会直接获取您的个人信息。</p>
        <p style="text-indent:2em;">（三）您理解并同意，在不透露您个人信息的前提下，平台有权对该服务的整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
        <p style="text-indent:2em;">（四）在您使用该服务时，平台可能自动接收并记录您的地图应用端上的服务器数值，包括但不限于IP地址等数据、您和用户要求取用的网页记录及各种记录、该服务的操作状态以及使用习惯等一些明确且客观反映在平台服务器端的基本记录信息。</p>
        <p style="text-indent:2em;">（五）微在线不允许任何人以任何手段收集、编辑、出售或者无偿传播您或其他用户的个人信息。一经发现您实施上述行为，微在线有权立即终止向您提供服务。</p>
        <p style="text-indent:2em;">六、免责声明</p>
        <p style="text-indent:2em;">（一）若平台已经明示其网络服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。</p>
        <p style="text-indent:2em;">（二）用户明确同意其使用平台网络服务，以及因此对平台网络服务加以依赖所存在的全部责任和风险将完全由其自己承担；因其使用平台网络服务过程的行为，以及因此而产生的一切后果也由其自己承担，平台对用户不承担任何责任。</p>
        <p style="text-indent:2em;">（三）平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
        <p style="text-indent:2em;">（四）用户因使用平台网络服务而产生的任何间接的、附带的、特殊的、结果性的或惩戒性的损害（包括但不限于利润亏损、商誉、使用服务、数据的损害或其它无形损失），平台概不负责，均由用户自行承担。</p>
        <p style="text-indent:2em;">（五）用户或用户的代理或关联人士违反本协议或相关法律，或因用户或用户的代理或关联人士（以参与者的身份行事）所做出的行为或疏忽而产生的任何损害，概由用户自行负责。</p>
        <p style="text-indent:2em;">七、拒绝提供担保</p>
        <p style="text-indent:2em;">用户个人对网络服务的使用承担风险。平台对此不作任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保、特定目的和不违反规定的适当担保作限制。平台不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保。</p>
        <p style="text-indent:2em;">八、有限责任</p>
        <p style="text-indent:2em;">平台对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务，在网上进行交易，非法使用网络服务或用户传送的信息有所变动。这些行为都有可能会导致本平台的形象受损，所以平台事先提出这种损害的可能性。</p>
        <p style="text-indent:2em;">九、对用户信息的存储和限制</p>
        <p style="text-indent:2em;">平台不对用户所发布信息的删除或储存失败负责。平台有判定用户的行为是否符合平台服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，平台有中断对其提供网络服务的权利。</p>
        <p style="text-indent:2em;">十、用户管理</p>
        <p style="text-indent:2em;">（一）用户单独承担发布内容的责任。</p>
        <p style="text-indent:2em;">（二）用户对服务的使用是根据所有适用于平台的国家法律、地方法律和国际法律标准的。</p>
        <p style="text-indent:2em;">（三）用户必须遵循：</p>
        <p style="text-indent:2em;">1、从中国境内向外传输技术性资料时必须符合中国有关法规；</p>
        <p style="text-indent:2em;">2、使用网络服务不作非法用途；</p>
        <p style="text-indent:2em;">3、不干扰或混乱网络服务；</p>
        <p style="text-indent:2em;">4、遵守所有使用网络服务的网络协议、规定、程序和惯例；</p>
        <p style="text-indent:2em;">5、招商引资活动符合法律规定及双方约定；</p>
        <p style="text-indent:2em;">6、用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐性的、伤害性的、庸俗的，淫秽等信息资料；</p>
        <p style="text-indent:2em;">7、另外，用户也不能传输何教唆他人构成犯罪行为的资料；不能传输助长国内不利条件和涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。未经许可而非法进入其它电脑系统是禁止的；</p>
        <p style="text-indent:2em;">8、若用户的行为不符合以上提到的服务条款，平台将作出独立判断立即取消用户服务账号。用户需对自己在网上的行为承担法律责任；</p>
        <p style="text-indent:2em;">9、用户若在平台上散布和传播反动、色情或其他违反国家法律的信息，平台的系统记录有可能作为用户违反法律的证据。</p>
        <p style="text-indent:2em;">十一、保障用户</p>
        <p style="text-indent:2em;">同意保障和维护平台全体成员的利益，违反服务条款的损害补偿费用等。</p>
        <p style="text-indent:2em;">十二、结束服务</p>
        <p style="text-indent:2em;">（一）平台可随时根据实际情况中断一项或多项网络服务。平台不需对任何个人或第三方负责而随时中断服务。用户对后来的条款修改有异议，或对平台的服务不满，可以行使如下权利：</p>
        <p style="text-indent:2em;">1、停止使用平台的网络服务；</p>
        <p style="text-indent:2em;">2、通告平台停止对该用户的服务。</p>
        <p style="text-indent:2em;">（二）结束用户服务后，用户使用网络服务的权利马上中止。从那时起，用户有权利，平台也没有义务传送任何未处理的信息或未完成的服务给户或第三方。</p>
        <p style="text-indent:2em;">十三、通告</p>
        <p style="text-indent:2em;">所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。</p>
        <p style="text-indent:2em;">十四、参与广告策划</p>
        <p style="text-indent:2em;">用户在他们发表的信息中加入宣传资料或参与广告策划，在本平台的免费服务上展示他们的招商条件等，任何这类宣传方法，只是在相应的用户和入驻商户之间发生。平台不承担任何责任，平台没有义务为这类广告负任何责任。</p>
        <p style="text-indent:2em;">十五、网络服务内容的所有权</p>
        <p style="text-indent:2em;">（一）平台定义的网络服务内容包括：文字、软件、声音、图片、录像、图表、广告中的全部内容；电子邮件的全部内容；本平台为用户提供的其他信息。</p>
        <p style="text-indent:2em;">（二）所有这些内容受版权、商标、标签和其它财产所有权法律的保护。</p>
        <p style="text-indent:2em;">（三）所以，用户只能在本平台和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
        <p style="text-indent:2em;">（四）平台所有的文章版权归原文作者和平台共同所有，任何人需要转载本平台的文章，必须征得原文作者或平台授权。</p>
        <p style="text-indent:2em;">十六、法律及管辖</p>
        <p style="text-indent:2em;">（一）网络服务条款要与中华人民共和国的法律解释相一致，用户和平台一致同意服从高等法院所有管辖。如发生平台服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户产生法律效力和影响。</p>
        <p style="text-indent:2em;">（二）因本用户协议产生纠纷的，用户可向网络服务提供者所在地人民法院提起诉讼。</p>
        <p style="text-indent:2em;">（三）一经注册成为本网站的用户，即代表同意签署本用户协议并愿意遵守相关条款约束。</p>
    </div>
</div>
</template>

<style scoped>
.content{
    text-align: left;
    padding: 30px 20px;
}
</style>
